import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown'; // นำเข้า react-markdown

export default function ChatGPTUI() {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendMessage = async (userMessage) => {
    const newMessages = [
      ...messages,
      { role: 'user', content: userMessage },
    ];
    setMessages(newMessages);
    setIsLoading(true);

    try {
      const response = await fetch('https://dashscope-intl.aliyuncs.com/compatible-mode/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer sk-a4fc2880dcfb482d82f44f790c04d3e8`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'qwen-plus',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            ...newMessages.map(msg => ({ role: msg.role, content: msg.content })),
          ],
        }),
      });

      const data = await response.json();
      if (response.ok) {
        const botResponse = data.choices[0].message.content; // Markdown content
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', content: botResponse },
        ]);
      } else {
        console.error('Error:', data);
        alert('Failed to get a response from the API.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while connecting to the API.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        background: '#50409a',
        color: '#fff',
        padding: '16px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Header Section */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '16px',
        }}
      >
        <img
          src="https://siamai.tech/images/Final-V-3-2-Company-Profile-1-png-1732902875590.webp"
          alt="Siam AI Logo"
          style={{ height: '40px', marginRight: '8px' }}
        />
        <h1 style={{ margin: 0, color: '#f47dba', fontSize: '20px' }}>GPT</h1>
      </div>

      {/* Chat Messages Section */}
      <div
        style={{
          flex: 1,
          overflowY: 'auto',
          marginBottom: '16px',
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '8px',
          backgroundColor: '#fff',
        }}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              textAlign: message.role === 'user' ? 'right' : 'left',
              margin: '8px 0',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                padding: '8px 12px',
                borderRadius: '16px',
                backgroundColor: message.role === 'user' ? '#f47dba' : '#ddd',
                color: message.role === 'user' ? '#fff' : '#000',
              }}
            >
              {message.role === 'assistant' ? (
                <ReactMarkdown>{message.content}</ReactMarkdown>
              ) : (
                message.content
              )}
            </div>
          </div>
        ))}
        {isLoading && (
          <div style={{ textAlign: 'left', margin: '8px 0', color: '#ddd' }}>
            กำลังพิมพ์ข้อความ...
          </div>
        )}
      </div>

      {/* Input Section */}
      <div style={{ display: 'flex', gap: '8px' }}>
        <input
          type="text"
          placeholder="พิมพ์ข้อความ..."
          style={{
            flex: 1,
            padding: '8px',
            borderRadius: '8px',
            border: '1px solid #f47dba',
            color: '#50409a',
            backgroundColor: '#fff',
            outline: 'none',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.target.value.trim()) {
              handleSendMessage(e.target.value.trim());
              e.target.value = '';
            }
          }}
        />
        <button
          style={{
            padding: '8px 16px',
            borderRadius: '8px',
            backgroundColor: '#f47dba',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => {
            const input = document.querySelector('input[type="text"]');
            if (input.value.trim()) {
              handleSendMessage(input.value.trim());
              input.value = '';
            }
          }}
        >
          ส่ง
        </button>
      </div>
      <h6 style={{ margin: 10, color: '#f47dba', textAlign: 'center' }}>Siam A.I. Co., Ltd. has no legal affiliation, either de facto or de jure, with any other legal entity whose name or trademark resembles that of the Company, in relation to the conduct of activities or transactions, whether online or on any platform.</h6>
    </div>
  );
}
